<template>
      <div class="input__container">
            <div :class="'input__effect ' + filled">
                  <input :style="backgroundStyles()" class="mt-3" type="text" :value="value" :placeholder="placeholder" @input="$emit('input', $event.target.value)" :class="'effect ' + has__icon + is__not__linear" @change="$emit('change', $event.target.value)"/>
                  <img v-if="icon" class="input__icon inline-block end--icon" :src="require(`@/assets/icons/svg/${icon}.svg`)"/>
            </div>
      </div>
</template>

<script>
      export default {
            data: () => ({
                  show: false,
            }),
            computed: {
                  filled() {
                        if (!this.show && this.value) return "has__content";
                        return "";
                  },
                  has__icon() {
                        if (this.icon) return "input__has__icon";
                        return ""
                  },
                  is__not__linear() {
                        if (!this.linear) return " not__linear";
                        return ""
                  },
                  focus__border() {
                        return {
                              "background-color": this.bgColor,
                        };
                  },
                  isEndIcon() {
                        return this.end;
                  }
            },
            props: {
                  value: { type: String, required: false, default: "" },
                  label: { type: String, required: false, default: "" },
                  // hint: { type: String, required: false, default: "" },
                  icon: { type: String, required: false, default: "" },
                  placeholder: { type: String, required: false, default: "" },
                  color: { type: String, required: false, default: "indigo" },
                  bgColor: { type: String, required: false, default: "" },
                  // linear: { type: Boolean, required: false, default: false }
            },
            methods:{
                  backgroundStyles() {
                        return {
                              'background-color': `${this.bgColor? this.bgColor : ""}`,
                              'border': '1px solid #13131333'
                        }
                  },
            },
            beforeDestroy(){
                        document.removeEventListener('click', function(){
                        })
                  }
            };
</script>

<style lang='scss'>
@import "Input";
</style>